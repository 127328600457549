import React from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Button,
    Container,
    Grid,
    Paper,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const BootCampDay2 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            minHeight: "100vh",
            background: "#51ade5"
        }}>
            <Navbar />
            <Container maxWidth="lg" sx={{ flexGrow: 1, py: 4 }}>
                <Typography
                    variant="h3"
                    align="center"
                    gutterBottom
                    sx={{
                        color: "white",
                        fontWeight: "bold",
                        mb: 4,
                        textShadow: "2px 2px 4px rgba(0,0,0,0.3)"
                    }}
                >
                    Day 2: Resume Writing Assistance
                </Typography>

                <Grid container spacing={4}>
                    {/* Video and Description Section */}
                    <Grid item xs={12}>
                        <Paper
                            elevation={10}
                            sx={{
                                borderRadius: 3,
                                overflow: 'hidden',
                                background: "rgba(255,255,255,0.9)"
                            }}
                        >
                            <Grid container>
                                {/* Video Column */}
                                <Grid
                                    item
                                    xs={12}
                                    md={7}
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <iframe
                                        title="Resume Writing Video"
                                        src="https://www.youtube.com/embed/your-video-id"
                                        style={{
                                            width: "100%",
                                            height: isMobile ? "300px" : "400px",
                                            borderRadius: "16px",
                                            boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
                                        }}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                </Grid>

                                {/* Description Column */}
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        p: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: 2,
                                            color: "primary.main"
                                        }}
                                    >
                                        Crafting the Perfect Resume
                                    </Typography>
                                    <Typography variant="body1">
                                        Watch our detailed video tutorial on creating a compelling resume. Learn how to structure your resume effectively and make a lasting impression with recruiters.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Single Card Section */}
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                borderRadius: 3,
                                background: "rgba(255, 255, 255, 0.9)",
                                textAlign: "center",
                                p: 4,
                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                                transition: 'transform 0.3s ease-in-out',
                                   '&:hover': {
                                     transform: 'scale(1.05)'
                                   }
                            }}
                            elevation={6}
                        >
                            <CardContent>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: "bold",
                                        mb: 2,
                                        color: "primary.main"
                                    }}
                                >
                                    Resume Template Access
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 3 }}>
                                    Download our professional resume template and get started on your resume today!
                                </Typography>
                                <Button
                                    sx={{backgroundColor: "#1cdb35"}}
                                    variant="contained"
                                    color="success"
                                    onClick={() => alert('Resume Template Download Coming Soon!')}
                                >
                                    Download Template
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </Box>
    );
};

export default BootCampDay2;
