import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/login");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClick = () => {
    navigate("/update-profile");
    setAnchorEl(null);
  };


  const handleMembersClick = () => {
    navigate("/dashboard");
    setAnchorEl(null);
  };


  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBootcampClick = () => {
    navigate("/bootcamp");
    setDrawerOpen(false);
  };

  const handleAppointmentClick = () => {
    navigate("/e-meets");
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white",
        color: "#333",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Toolbar sx={{ px: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          {/* Left Side */}
          <Box display="flex" alignItems="center">
            <Box
              component="img"
              src={"../images/company-logo.png"}
              alt="Company Logo"
              sx={{ height: 100, mr: 2, padding: "10px", cursor: "pointer" }}
              onClick={() => {navigate('/dashboard')}}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold", display: { xs: "none", md: "block", cursor: "pointer"} }} onClick={() => {navigate('/dashboard')}}>
              JobSeeker Circle
            </Typography>
          </Box>

          {/* Right Side for Large Screens */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Button color="inherit" sx={{ color: "#333", mr: 2 }} onClick={handleMembersClick}>
              Members
            </Button>
            <Button color="inherit" sx={{ color: "#333", mr: 2 }} onClick={handleAppointmentClick}>
              E-Meets
            </Button>
            <Button color="inherit" sx={{ color: "#333", mr: 2 }} onClick={handleBootcampClick}>
              Weekly Bootcamp
            </Button>
            <IconButton onClick={handleProfileMenuOpen}>
              <AccountCircle sx={{ color: "#333", fontSize: 40 }} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleProfileMenuClose}>
              <MenuItem onClick={handleProfileClick}>Profile Settings</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>

          {/* Hamburger Menu for Mobile Screens */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Drawer for Mobile Navigation */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <Box display="flex" alignItems="center" p={2}>
            <Box
              component="img"
              src={"../images/company-logo.png"}
              alt="Company Logo"
              sx={{ height: 50, mr: 2 }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              JobSeeker Circle
            </Typography>
          </Box>
          <Divider />
          <List>
            <ListItem button onClick={() => navigate("/dashboard")}>
              <ListItemText primary="Members" />
            </ListItem>
            <ListItem button onClick={() => navigate("/e-meets")}>
              <ListItemText primary="E-meets" />
            </ListItem>
            <ListItem button onClick={handleBootcampClick}>
              <ListItemText primary="Weekly Bootcamp" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={handleProfileClick}>
              <ListItemText primary="Profile Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;