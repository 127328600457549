import React from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Button,
    Container,
    Grid,
    Paper,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const BootCampDay1 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            minHeight: "100vh",
            background: "#51ade5"
        }}>
            <Navbar />
            <Container maxWidth="lg" sx={{ flexGrow: 1, py: 4 }}>
                <Typography
                    variant="h3"
                    align="center"
                    gutterBottom
                    sx={{
                        color: "white",
                        fontWeight: "bold",
                        mb: 4,
                        textShadow: "2px 2px 4px rgba(0,0,0,0.3)"
                    }}
                >
                    Day 1: Conduct Market Research
                </Typography>

                <Grid container spacing={4}>
                    {/* Image and Description Section */}
                    <Grid item xs={12}>
                        <Paper
                            elevation={10}
                            sx={{
                                borderRadius: 3,
                                overflow: 'hidden',
                                background: "rgba(255,255,255,0.9)"
                            }}
                        >
                            <Grid container>
                                {/* Image Column */}
                                <Grid
                                    item
                                    xs={12}
                                    md={7}
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img
                                        src="https://via.placeholder.com/600x400"
                                        alt="Market Research"
                                        style={{
                                            width: "100%",
                                            height: isMobile ? "300px" : "400px",
                                            borderRadius: "16px",
                                            boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
                                        }}
                                    />
                                </Grid>

                                {/* Description Column */}
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        p: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: 2,
                                            color: "primary.main"
                                        }}
                                    >
                                        Understand Your Role Better
                                    </Typography>
                                    <Typography variant="body1">
                                        Learn how to conduct effective market research to gain clarity on the roles you are targeting. This will help you align your skills with industry needs and job requirements.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Cards Section */}
                    
                    <Grid item xs={12}>
                               <Card
                                 sx={{
                                   borderRadius: 3,
                                   display: 'flex',
                                   flexDirection: 'column',
                                   alignItems: 'center', 
                                   textAlign: 'center',  
                                   transition: 'transform 0.3s ease-in-out',
                                   '&:hover': {
                                     transform: 'scale(1.05)'
                                   }
                                 }}
                                 elevation={6}
                               >
                                 <CardContent
                                   sx={{
                                     display: 'flex',
                                     flexDirection: 'column',
                                     alignItems: 'center',  // Center align horizontally
                                     justifyContent: 'center', // Center align vertically if needed
                                     width: '100%', // Ensure full width alignment
                                   }}
                                 >
                                   <Typography
                                     variant="h5"
                                     sx={{
                                       fontWeight: "bold",
                                       mb: 2,
                                       color: "primary.main"
                                     }}
                                   >
                                     Content
                                   </Typography>
                                   <Typography variant="body2" sx={{ mb: 2 }}>
                                     Download a PDF of common interview questions with sample answers that you can customize to suit your personal experience and goals.
                                   </Typography>
                                   {/*<Button
                                     variant="contained"
                                     color="success"
                                     onClick={() => alert('Common Q&A PDF Download Coming Soon!')}
                                   >
                                     Download Q&A
                                   </Button> */}
                                 </CardContent>
                               </Card>
                             </Grid>
                   

                </Grid>
            </Container>
            <Footer />
        </Box>
    );
};

export default BootCampDay1;
