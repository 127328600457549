
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const ProfCard1 = ({ _id, name, picture, currentRole, city, onClick }) => {
  const navigate = useNavigate();

  

  return (
    <Box
      onClick={onClick}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "210px",
        height: "340px",
        padding: "15px",
        backgroundColor: "#f9f9f9",
        borderRadius: "12px",
        boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.15)",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.25)",
          transform: "scale(1.05)",
        },
      }}
    >
      <img
        src={picture || "../images/Account_Dummy.png"}
        alt={name}
        style={{
          width: "200px",
          height: "200px",
          objectFit: "cover",
          borderRadius: "12px",
        }}
      />
      <Typography
        variant="h6"
        textAlign="center"
        sx={{
          marginTop: "12px",
          fontWeight: "bold",
          color: "#333",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
        }}
      >
        {name}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "180px",
          marginTop: "12px",
          padding: "8px 16px",
          border: "1px solid #6495ED",
          borderRadius: "8px",
          backgroundColor: "#CFE8F6",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Typography variant="body2" textAlign="center" sx={{ color: "#4B9CD3" }}>
          {currentRole}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "180px",
          marginTop: "12px",
          padding: "8px 16px",
          border: "1px solid #6495ED",
          borderRadius: "8px",
          backgroundColor: "#CFE8F6",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Typography variant="body2" textAlign="center" sx={{ color: "#4B9CD3" }}>
          {city}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfCard1;