//V1

import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, TextField, Typography, Alert, LinearProgress, IconButton, InputAdornment } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [checkbox, setCheckbox] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Success message state
  const [progress, setProgress] = useState(0); // Linear progress bar state
  const [formVisible, setFormVisible] = useState(true); // Toggle form visibility

  // Validation flags
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    // Password should be at least 8 characters long, contain a number, uppercase letter, and special character
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear error before submit

    // Remove extra spaces
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    // Validate email and password
    if (!validateEmail(trimmedEmail)) {
      setEmailError(true);
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    setEmailError(false);

    if (!validatePassword(trimmedPassword)) {
      setPasswordError(true);
      setErrorMessage(
        "Password must be at least 8 characters long, and include at least one uppercase letter, one number, and one special character."
      );
      return;
    }
    setPasswordError(false);

    if (!checkbox) {
      setErrorMessage("You must agree to the terms and conditions.");
      return;
    }

    try {
        await axios.post(
        `https://d18ye91bhcj72x.cloudfront.net/api/auth/register`,
        {
          email: trimmedEmail,
          password: trimmedPassword,
        }
      );

      // Show success message and hide the form
      setSuccessMessage("A verification link has been sent to your email address. Please check your inbox to verify your account.");
      setFormVisible(false); // Hide form after successful registration

      // Start progress bar
      setProgress(0);
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
            navigate("/login"); // Redirect to login page when progress reaches 100%
            return 100;
          }
          return Math.min(oldProgress + 50, 100); // Progress increases every second
        });
      }, 3000);
      
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data.message === "User already registered") {
        setErrorMessage("User already registered. Please log in.");
      } 
      setErrorMessage(err.response?.data?.message || "Registration failed.");
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#fff",
        width: { xs: "90%", sm: "60%", md: "43%", xl: "24%" },
        margin: { xs: "0 15px" },
      }}
      padding="20px"
      borderRadius="5%"
    >
      {/* Success message and linear progress bar after signup */}
      {!formVisible && successMessage && (
        <Box sx={{ width: '100%', marginBottom: "20px" }}>
          <Alert severity="success">{successMessage}</Alert>
          <LinearProgress variant="determinate" value={progress} sx={{ marginTop: "10px" }} />
        </Box>
      )}

      {/* Display form only if formVisible is true */}
      {formVisible && (
        <>
          <Box textAlign="center">
            <Box
              component="img"
              alt="logo"
              src="../images/company-logo.png"
              sx={{ objectFit: "cover", height: "130px", width: "130px" }}
            />
            <Typography variant="h5" padding="10px" textAlign="center">
              SIGN UP
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ width: { xs: "18rem", sm: "25rem" } }}
            >
              <TextField
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                sx={{ margin: "7px 0" }}
                label="Email"
                variant="outlined"
                type="email"
                required
                error={emailError} // Trigger red border if there's an erro
                helperText={emailError && "Please enter a valid email."}
              />
              <TextField
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                minLength={8}
                sx={{ margin: "7px 0" }}
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                required
                error={passwordError}
                //helperText={passwordError && "Password must be at least 8 characters, with uppercase, number, and special character."}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box margin="20px 0" display="flex" alignItems="center">
                <input onClick={() => setCheckbox(!checkbox)} type="checkbox" />
                <Typography sx={{ ml: 1, fontSize: "14px" }}>
                  I agree to the{" "}
                  <a
                    href="https://www.jobseekercircle.com/terms-and-conditions/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1976d2", textDecoration: "none" }}
                  >
                    Terms
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.jobseekercircle.com/privacy-policy-2/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1976d2", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </a>
                  .
                </Typography>
              </Box>

              {/* Error message display */}
              {errorMessage && (
                <Alert severity="error" sx={{ marginBottom: "20px" }}>
                  {errorMessage}
                </Alert>
              )}

              <Button
                disabled={!checkbox}
                type="submit"
                sx={{ margin: "6px 0", backgroundColor: "#1cdb35" }}
                variant="contained"
              >
                Sign Up
              </Button>

              <Button onClick={() => navigate("/login")} sx={{ margin: "6px 0" }} variant="text">
                Already have an account? Login
              </Button>
            </Box>
          </form>
        </>
      )}
    </Box>
  );
};

export default Register;







