import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Slider from "react-slick"
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Bootcamp = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100vw", minHeight: "100vh" }}>
            <Navbar />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    minHeight: "calc(100vh - 64px)", // Adjust for Navbar height
                    backgroundColor: "#51ade5",
                    paddingY: "20px",
                    textAlign: "center",
                }}
            >
                {/* Bootcamp Introduction */}
                <Box
                    sx={{
                        width: "80%",
                        maxHeight: "80vh",
                        marginBottom: "20px",
                    }}
                >
                    <Slider {...settings}>
                        <Box
                            component="img"
                            src={"../images/Weekly-Bootcamp-2.png"}
                            alt="Bootcamp Image 1"
                            sx={{
                                width: "100%",
                                maxHeight: "80vh",
                                objectFit: "contain",
                            }}
                        />
                        <Box
                            component="img"
                            src={"../images/Mentorpic.png"}
                            alt="Bootcamp Image 2"
                            sx={{
                                width: "100%",
                                maxHeight: "80vh",
                                objectFit: "contain",
                            }}
                        />

                        <Box
                            component="img"
                            src={"../images/DailySchedule.png"}
                            alt="Bootcamp Image 2"
                            sx={{
                                width: "100%",
                                maxHeight: "80vh",
                                objectFit: "contain",
                            }}
                        />

                        <Box
                            component="img"
                            src={"../images/steps-Bootcamp.png"}
                            alt="Bootcamp Image 3"
                            sx={{
                                width: "100%",
                                maxHeight: "80vh",
                                objectFit: "contain",
                            }}
                        />
                    </Slider>
                </Box>
                <Typography
                    variant="h4"
                    sx={{
                        marginTop: "40px",
                        fontWeight: "bold",
                        color: "#ffffff",
                    }}
                >
                    Welcome to the Weekly Bootcamp
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginTop: "10px",
                        color: "#ffffff",
                        maxWidth: "80%",
                    }}
                >
                    Jobseeker circle&apos;s weekly bootcamp can help you expedite your job search just within a week!
                    Get daily exercises, videos, and templates to help you expedite your job search and land a job faster.
                </Typography>

                {/* Weekly Schedule */}
                <Box
                    sx={{
                        marginTop: "40px",
                        width: "75%",
                        padding: "20px",
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        textAlign: "left",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            marginBottom: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                        }}
                    >
                        Weekly Bootcamp Schedule
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Day 1"
                                secondary="Conduct market research to get role clarity."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Day 2"
                                secondary="Update your resume – Watch our exclusive video (approx. 1 hour) to help you update your resume and get access to a simple resume template."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Day 3"
                                secondary="Learn how to build your profile and use LinkedIn effectively (video length approx. 1 hour) and download templates of scripts to help you connect better with relevant people."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Day 4"
                                secondary="Prepare for interview (video length approx. 1 hour) and download common questions and their answers which you can tailor based on your experience."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Day 5"
                                secondary="Open Q&A with our job search mentor every Friday."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Bootcamp Benefits */}
                <Box
                    sx={{
                        marginTop: "40px",
                        width: "75%",
                        padding: "20px",
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        textAlign: "left",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            marginBottom: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                        }}
                    >
                        Benefits of Jobseeker Circle’s Weekly Bootcamp
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="1. Streamlined Job Search Process"
                                secondary="Kickstart your job search with a structured daily plan that covers all the essentials—market research, resume building, LinkedIn optimization, and interview prep—all in one week!"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="2. Clear Path to Role Clarity"
                                secondary="Discover which roles align with your skills and interests on Day 1, helping you focus your efforts on the right opportunities from the start."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="3. Professional Resume Refresh"
                                secondary="Learn expert resume-building techniques from a one-hour guided video and use a tried-and-tested template to make your resume stand out to recruiters."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="4. LinkedIn Networking Mastery"
                                secondary="Day 3’s session will teach you to make meaningful LinkedIn connections and leverage your profile to attract recruiters, with templates to streamline your outreach efforts."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="5. Confident Interview Preparation"
                                secondary="Gain access to common interview questions and answers, plus tips to tailor responses to your unique experience, giving you confidence and clarity when it matters most."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="6. Live Mentorship & Q&A Support"
                                secondary="End the week with a live Q&A session where you can ask any job search questions, receive personalized guidance, and gain new insights from an experienced job search mentor."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="7. Affordable, Intensive Training"
                                secondary="Get all these resources, expert insights, and personal support for a very affordable price—designed to save you time and help you land a job faster."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="8. Flexible Start Date"
                                secondary="Even if you sign up mid-week, your bootcamp will begin on Monday, giving you the flexibility to plan ahead and get ready for a dedicated week of job search acceleration."
                            />
                        </ListItem>
                    </List>
                </Box>
                <Box
                    sx={{
                        marginTop: "20px",
                        textAlign: "center",
                    }}
                >
                    <button
                        onClick={() => {
                            window.location.href = "https://buy.stripe.com/eVa6oy82TetX5Yk5kt";
                        }}
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "#1cdb35",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            fontSize: "16px",
                            cursor: "pointer",
                        }}
                    >
                        Join Now
                    </button>
                </Box>

            </Box>

            <Footer />
        </div>
    );
};

export default Bootcamp;
