import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Typography } from '@mui/material';

const UpdateStatusButton = ({ appointmentId }) => {
  const [status, setStatus] = useState('pending');

  const handleStatusUpdate = async () => {
    try {
      const response = await axios.post('https://d18ye91bhcj72x.cloudfront.net/api/profile/no-calls', {
        appointmentId
      });
      if (response.status === 200) {
        setStatus('cancelled'); 
        console.log(response.data.message);
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <Box>
      
      <Button variant="contained" color="primary" sx={{backgroundColor:"#1565c0"}} onClick={handleStatusUpdate}>
        NO CALLS FOR COMING WEEK
      </Button>
    </Box>
  );
};

export default UpdateStatusButton;
