import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {

  const navigate = useNavigate();

  const handleMembersClick = () => {
    navigate("/dashboard");
  };

  const handleBootcampClick = () => {
    navigate("/bootcamp");
  }

  const handleAppointmentClick = () => {
    navigate("/e-meets");
  };

  const handleInstagramRedirect = () => {
    window.location.href = "https://www.instagram.com/jobseekercircle";
  };

  const handleLinkedInRedirect = () => {
    window.location.href = "https://www.linkedin.com/company/jobseeker-circle/";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        backgroundColor: "white",
        
        px: 5,
        textAlign: "center",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "100vw",
        boxShadow: 1,
        gap: 5
      }}
    >
      
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1,py:3 }}>
        <Box
          component="img"
          src={"../images/company-logo.png"}
          alt="Company Logo"
          sx={{ height: 100, cursor: "pointer", mb: 1 }}
        />
        <Typography variant="body2" color="textSecondary">
          © {new Date().getFullYear()} JobSeekerCircle Pty. Ltd. All rights reserved.
        </Typography>
      </Box>

      
      <Box sx={{ display: "flex", flexDirection: {xs:"column", md:"row"}, gap: {xs: 2, md: 5}, flex: 1, justifyContent: "center",marginLeft:{xs: "0", md:"80px"}}}>
        <Button color="inherit" sx={{ color: "#333" }} onClick={handleMembersClick}>
          Members
        </Button>
        <Button color="inherit" sx={{ color: "#333" }} onClick={handleAppointmentClick}>
          E-Meets
        </Button>
        <Button color="inherit" sx={{ color: "#333" }} onClick={handleBootcampClick}>
          Weekly Bootcamp
        </Button>
      </Box>

      
      <Box sx={{ display: "flex", gap: 3, alignItems: "center", flex: 1, justifyContent: "center", }}>
      <Typography onClick={handleInstagramRedirect} sx={{ cursor: "pointer" }}>
          <InstagramIcon sx={{ color: "#b0b0b0", fontSize: 50 }} />
        </Typography>
        <Typography onClick={handleLinkedInRedirect} sx={{ cursor: "pointer" }}>
          <LinkedInIcon sx={{ color: "#b0b0b0", fontSize: 50 }} />
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
