
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import axios from "axios";
import Navbar from "../components/Navbar";
import ProfCard1 from "../components/ProfCard1";
import Footer from "../components/Footer";



const Dashboard = () => {
  const [profiles, setProfiles] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(8);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`https://d18ye91bhcj72x.cloudfront.net/api/profile/get-profile`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
        });
        setUserProfile(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error.response || error.message);
      }
    };

    const fetchProfiles = async () => {
      try {
        const response = await axios.get(`https://d18ye91bhcj72x.cloudfront.net/api/profile/members`);
        if (Array.isArray(response.data)) {
          // Filter out profiles where `firstName` is null
          const validProfiles = response.data.filter(
            (profile) => profile.firstName && profile.lastName
          );
          setProfiles(validProfiles);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching profiles:", error.response || error.message);
      } finally {
        setLoading(false);
      }
    };


    fetchUserProfile();
    fetchProfiles();
  }, []);

  const handleScroll = () => {
    const bottom =
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 50;

    if (bottom && visibleCount < profiles.length) {
      setVisibleCount((prevCount) => prevCount + 8);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleCount, profiles]);

  // Filter profiles to exclude the logged-in user's profile
  const filteredProfiles = profiles.filter(
    (profile) => profile._id !== userProfile?._id
  );

  // Highlighted profiles in the same city
  const matchedProfiles = filteredProfiles
    .filter((profile) => profile.city === userProfile?.city)
    .slice(0, 8);

  // Profiles displayed on scrolling
  const displayedProfiles = filteredProfiles.slice(0, visibleCount);

  const handleCardClick = (id) => {
    navigate(`/get-profile/${id}`);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column",  width:"100vw" }}>
      <Navbar />
      <Box sx={{ width: "100%", minHeight: "100vh", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", }}>
        <Box
          sx={{
            color: "white",
            py: 4,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Meet other professionals
          </Typography>
          <Typography variant="subtitle1">
            Who are also on their job search journey.
          </Typography>
        </Box> 

        {/* Highlighted Profiles Section */}
        <Box sx={{ py: 4, width: "100%" }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}
          >
            Excited to connect? For your weekly calls,
            <Typography
            variant="h5"
            sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}
          > We will pair you with these inspiring members ready to help you grow!</Typography>
           
          </Typography>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{ 
              width: "100%", 
              maxWidth: "1200px", 
              margin: "0 auto", 
              paddingX: { xs: 7, sm: 10, lg:0 } 
            }}
          >
            {loading ? (
              <Typography variant="h6" color="textSecondary" textAlign="center">
                Loading profiles...
              </Typography>
            ) : matchedProfiles.length === 0 ? (
              <Typography variant="h6" color="textSecondary" textAlign="center">
                No members found in your city.
              </Typography>
            ) : (
              matchedProfiles.map((profile, index) => (
                <Grid  
                item 
                xs={12} 
                sm={6} 
                md={4} 
                lg={3}
      
                key={profile._id || index}>
                  <ProfCard1
                    name={`${profile.firstName} ${profile.lastName}`}
                    currentRole={profile.currentRole}
                    picture={profile.profileImage?.url || ""}
                    city={profile.city}
                    onClick={() => handleCardClick(profile._id)}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>

        {/* Full Profile List Section */}
        <Box sx={{ py: 4, width: "100%" }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}
          >
            Other Jobseeker Circle members who are also, 
            <Typography
            variant="h5"
            sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}
          >ready to connect, support, and inspire each other to success!</Typography>
          </Typography>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems='center'
            sx={{
              width: "100%",
              maxWidth: "1200px",
              margin: "0 auto",
              paddingX: { xs: 7, sm: 10, lg:0 },
        
              overflow: "hidden",
            }}
          >
            {loading ? (
              <Typography variant="h6" color="textSecondary" textAlign="center">
                Loading profiles...
              </Typography>
            ) : displayedProfiles.map((profile, index) => (
              <Grid 
              item 
              xs={12} 
              sm={6} 
              md={4} 
              lg={3} 
              key={profile._id || index}>
                <ProfCard1
                  name={`${profile.firstName} ${profile.lastName}`}
                  currentRole={profile.currentRole}
                  picture={profile.profileImage?.url || ""}
                  city={profile.city}
                  onClick={() => handleCardClick(profile._id)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};


export default Dashboard;
