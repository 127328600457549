import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import axios from "axios";
import AppointmentCard from "../components/AppointmentCard";
import UpdateStatusButton from "../components/UpdateStatusButton";
import { useNavigate } from "react-router-dom";

const Appointments = () => {
 
 const navigate = useNavigate();
  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem("authToken");
     if (!token) throw new Error("No token found in localStorage");

      const response = await axios.get(
        "https://d18ye91bhcj72x.cloudfront.net/api/appointments",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAppointments(response.data.appointments);
      
    } catch (error) {
      console.error("Error fetching appointments:", error);
      setAppointments([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const [view, setView] = useState("Upcoming");
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);

  

  const filterAppointments = (status) => {
    if (status === "Upcoming") {
      // Include both scheduled and pending appointments
      return appointments.filter(
        (appointment) => appointment.status === "scheduled" || appointment.status === "canceled" || appointment.status === "confirmed"
      );
    }
    // Include only completed appointments for the Completed tab
    return appointments.filter((appointment) => appointment.status === "completed");
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "scheduled":
        return "yellow";
      case "canceled":
        return "red";
      case "completed":
        return "green";
      case "confirmed":
        return "blue";
      default:
        return "gray";
    }
  };

  const handleCardClick = (id) => {
    navigate(`/get-profile/${id}`);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "#51ade5",
          gap: {xs: 1 , lg: 3},
          py: 5,
        }}
      >
        <Box>
          <ButtonGroup variant="contained">
            <Button
              onClick={() => setView("Upcoming")}
              sx={{
                backgroundColor: view === "Upcoming" ? "#1565c0" : "#e3f2fd",
                color: view === "Upcoming" ? "#fff" : "#1565c0",
              }}
            >
              Upcoming
            </Button>
            <Button
              onClick={() => setView("Completed")}
              sx={{
                backgroundColor: view === "Completed" ? "#1565c0" : "#e3f2fd",
                color: view === "Completed" ? "#fff" : "#1565c0",
              }}
            >
              Completed
            </Button>
          </ButtonGroup>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "1200px",
            gap: 3,
            px: 2,
          }}
        >
          {loading ? (
            <Typography variant="h6" color="#fff">
              Loading appointments...
            </Typography>
          ) : filterAppointments(view).length === 0 ? (
            <Typography variant="h6" color="#fff" textAlign="center">
              No {view.toLowerCase()} appointments to show.
            </Typography>
          ) : (
            filterAppointments(view).map((appointment) => (
              <AppointmentCard
                key={appointment._id}
                image={appointment.userB.profileImage?.url || ""}
                name={`${appointment.userB.firstName} ${appointment.userB.lastName}`}
                role={appointment.userB.currentRole}
                city={appointment.userB.city}
                date={new Date(appointment.date).toDateString()}
                time={appointment.timeSlot}
                status={appointment.status}
                statusColor={getStatusColor(appointment.status)}
                onClick={() => handleCardClick(appointment.userB._id)}
              />
            ))
          )}
        </Box>

        {view === "Upcoming" && <UpdateStatusButton />}
      </Box>
      <Footer />
    </div>
  );
};

export default Appointments;



