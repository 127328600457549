

///v2

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, TextField, Typography, Alert, IconButton, InputAdornment } from "@mui/material";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
  const navigate = useNavigate();
  const location = useLocation();

  // Check if redirected from email verification
  useEffect(() => {
    if (location.state && location.state.fromVerification) {
      setMessage("Email verified successfully. Please log in.");
    }
  }, [location]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setErrorMessage("");
    setMessage("");

    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (email === "" || password === "") {
      setErrorMessage("All fields are required.");
      return;
    }

    try {
      const response = await axios.post(`https://d18ye91bhcj72x.cloudfront.net/api/auth/login`, {
        email,
        password,
      });

      // Store JWT token in localStorage
      localStorage.setItem("authToken", response.data.token);

      // Clear input fields
      setEmail("");
      setPassword("");

      // Check if the profile needs to be completed
      if (response.data.completeProfile) {
        setMessage("Login successful.");
        navigate("/complete-profile");
      } else {
        setMessage("Login successful.");
        //console.log(localStorage.getItem("authToken"));
        navigate("/dashboard");
      }
    } catch (err) {
      console.error(err);
      // Display the specific error message returned by the backend
      if (err.response && err.response.data && err.response.data.message) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#fff",
        width: { xs: "90%", sm: "60%", md: "43%", xl: "30%" },
        margin: { xs: "0 15px" },
      }}
      padding="20px"
      borderRadius="5%"
    >
      <Box textAlign="center">
        <Box
          component="img"
          alt="logo"
          src="../images/company-logo.png"
          sx={{ objectFit: "cover", height: "130px", width: "130px" }}
        />
        <Typography variant="h5" padding="10px" textAlign="center">
          LOGIN
        </Typography>
      </Box>

      {/* Display email verification success message */}
      {message && (
        <Alert severity="success" sx={{ marginBottom: "10px" }}>
          {message}
        </Alert>
      )}

      {/* Display login error message */}
      {errorMessage && (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          {errorMessage}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: { xs: "18rem", sm: "25rem" } }}
        >
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            sx={{ margin: "7px 0" }}
            label="Email"
            variant="outlined"
            type="email"
            required
          />
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            sx={{ margin: "7px 0" }}
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            sx={{ margin: "6px 0", backgroundColor: "#1cdb35" }}
            variant="contained"
          >
            Log In
          </Button>
          <Button
            onClick={() => navigate("/")}
            sx={{ margin: "6px 0" }}
            variant="outlined"
          >
            Sign Up
          </Button>
        </Box>
      </form>
      <Typography
        variant="body2"
        sx={{ marginTop: "10px", cursor: "pointer", color: "#1976d2" }}
        onClick={() => navigate("/forgot-password")}
      >
        Forgot Password?
      </Typography>
    </Box>
  );
};

export default Login;

