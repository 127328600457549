import React, { useState } from "react";
import { Box, Button, TextField, Typography, Alert } from "@mui/material";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token"); 

    const handleSubmit = async (e) => {
        e.preventDefault();

        setErrorMessage("");
        setSuccessMessage("");

        
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(
                `https://d18ye91bhcj72x.cloudfront.net/api/auth/reset-password`,
                { token, newPassword: password } 
            );

            setSuccessMessage(response.data.message || "Password successfully updated!");
            setTimeout(() => {
                navigate("/login"); 
            }, 2000);
        } catch (err) {
            console.error(err);
            if (err.response && err.response.data && err.response.data.message) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage("Something went wrong. Please try again.");
            }
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundColor: "#fff",
                width: { xs: "90%", sm: "60%", md: "43%", xl: "30%" },
                margin: { xs: "0 15px" },
            }}
            padding="20px"
            borderRadius="5%"
        >
            {/* Logo Section */}
            <Box textAlign="center" marginBottom="20px">
                <img
                    src="../images/company-logo.png"
                    alt="Logo"
                    style={{ width: "130px", height: "130px" }}
                />
            </Box>

            <Box textAlign="center">
                <Typography variant="h5" padding="10px">
                    Reset Password
                </Typography>
            </Box>

            {/* Display success message */}
            {successMessage && (
                <Alert severity="success" sx={{ marginBottom: "10px" }}>
                    {successMessage}
                </Alert>
            )}

            {/* Display error message */}
            {errorMessage && (
                <Alert severity="error" sx={{ marginBottom: "10px" }}>
                    {errorMessage}
                </Alert>
            )}

            <form onSubmit={handleSubmit}>
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        width: { xs: "18rem", sm: "25rem" },
                        padding: "20px",
                        gap: "15px"
                    }}
                >
                    <TextField
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        label="New Password"
                        variant="outlined"
                        type="password"
                        required
                    />
                    <TextField
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        label="Confirm Password"
                        variant="outlined"
                        type="password"
                        required
                    />
                    <Button
                        type="submit"
                        sx={{ backgroundColor: "#1cdb35", paddingX: "5px" }}
                        variant="contained"
                    >
                        Reset Password
                    </Button>
                    <Button
                        onClick={() => navigate("/login")}
                        variant="outlined"
                    >
                        Back to Login
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default ResetPassword;
