
import React, { useState, useEffect } from "react";
import { Avatar, Typography, Chip, Box, Stack } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "axios";
import { useParams } from "react-router-dom";

const ProfilePage = () => {
  const { userId } = useParams()
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('authToken');

      try {
        const response = await axios.get(
          `https://d18ye91bhcj72x.cloudfront.net/api/profile/get-userProfile/${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setProfile(response.data);
       
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    
    };

    fetchProfile();
  }, [userId]);

  if (!profile) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#51ade5",
        }}
      >
        <Typography variant="h5" color="white">
          Loading profile...
        </Typography>
      </Box>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "#51ade5",
          padding: { xs: "16px", md: "0px" },
        }}
      >
        {/* Profile Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "1200px",
            margin: "20px auto",
            padding: "30px",
            borderRadius: "12px",
            boxShadow: 3,
            backgroundColor: "#fff",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: "20px",
            }}
          >
            {/* Profile Image */}
            <Avatar
              src={profile.profileImage?.url || ""}
              sx={{
                bgcolor: deepPurple[500],
                width: { xs: "150px", md: "200px" },
                height: { xs: "150px", md: "200px" },
                fontSize: "3rem",
                borderRadius: "50%",
              }}
              variant="rounded"
            >
              {profile.profileImage?.url ? "" : profile.firstName.charAt(0)}
            </Avatar>

            {/* Name and City */}
            <Stack
              spacing={1}
              sx={{ flex: 1, textAlign: { xs: "center", md: "left" }, marginX: { xs: "0px", md: "10px" } }}
            >
              <Typography variant="h4" fontWeight="semibold">
                {profile.firstName} {profile.lastName}
              </Typography>
              <Typography variant="h6" color="text.secondary" sx={{ marginY: "20px" }}>
                {profile.city}
              </Typography>
              <Chip
                label={profile.currentRole}
                variant="outlined"
                sx={{
                  borderRadius: "4px",
                  backgroundColor: "#00000",
                  width: "200px",
                  height: "35px",
                  color: "#808080",
                  fontWeight: "bold",
                  borderColor: "#787276",
                  borderWidth: "1px",
                }}
              />
            </Stack>
          </Box>

          {/* Bio and Availability */}
          <Box sx={{ marginTop: "40px" }}>
            <Typography variant="body1" color="text.secondary" gutterBottom>
              {profile.bio}
            </Typography>
            <Typography
              variant="subtitle1"
              color="#373737"
              gutterBottom
              sx={{ marginY: "20px" }}
            >
              Availability
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1} marginTop="10px">
              {profile.availability.length > 0 ? (
                profile.availability.map((available) => (
                  <Chip
                    key={available}
                    label={available}
                    color="primary"
                    variant="outlined"
                    sx={{
                      borderRadius: "4px",
                      backgroundColor: "#CFE8F6",
                      color: "#4B9CD3",
                      fontWeight: "bold",
                      borderColor: "#4B9CD3",
                      borderWidth: "1px",
                    }}
                  />
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No availability information provided.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default ProfilePage;