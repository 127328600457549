import React, { useState } from "react";
import { Box, Button, TextField, Typography, Alert } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setErrorMessage("");
        setSuccessMessage("");

        // Validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        try {
            const response = await axios.post(
                `https://d18ye91bhcj72x.cloudfront.net/api/auth/forgot-password`,
                { email }
            );

            setSuccessMessage(response.data.message || "Password reset email sent!");
            setEmail("");
        } catch (err) {
            console.error(err);
            if (err.response && err.response.data && err.response.data.message) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage("Something went wrong. Please try again.");
            }
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundColor: "#fff",
                width: { xs: "90%", sm: "60%", md: "43%", xl: "30%" },
                margin: { xs: "0 15px" },
            }}
            padding="20px"
            borderRadius="5%"
        >
            <Box textAlign="center">
                <Box
                    component="img"
                    alt="logo"
                    src="../images/company-logo.png"
                    sx={{ objectFit: "cover", height: "130px", width: "130px" }}
                />
                <Typography variant="h5" padding="10px">
                    Forgot Password
                </Typography>
            </Box>

            {/* Display success message */}
            {successMessage && (
                <Alert severity="success" sx={{ marginBottom: "10px" }}>
                    {successMessage}
                </Alert>
            )}

            {/* Display error message */}
            {errorMessage && (
                <Alert severity="error" sx={{ marginBottom: "10px" }}>
                    {errorMessage}
                </Alert>
            )}

            <form onSubmit={handleSubmit}>
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        width: { xs: "18rem", sm: "25rem" },
                        padding: "20px", // Add padding to the inner Box
                        gap: "15px" // Add gap to space out child elements
                    }}
                >
                    <TextField
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        label="Email"
                        variant="outlined"
                        type="email"
                        required
                    />
                    <Button
                        type="submit"
                        sx={{ backgroundColor: "#1cdb35", paddingX:"5px" }}
                        variant="contained"
                    >
                        Send Reset Link
                    </Button>
                    <Button
                        onClick={() => navigate("/login")}
                        variant="outlined"
                    >
                        Back to Login
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default ForgotPassword;
