import React from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Button,
    Container,
    Grid,
    Paper,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const BootCampDay3 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleDownload = () => {
        alert('LinkedIn Profile Checklist Download Coming Soon!');
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            minHeight: "100vh",
            background: "#51ade5"
        }}>
            <Navbar />
            <Container maxWidth="lg" sx={{ flexGrow: 1, py: 4 }}>
                <Typography
                    variant="h3"
                    align="center"
                    gutterBottom
                    sx={{
                        color: "white",
                        fontWeight: "bold",
                        mb: 4,
                        textShadow: "2px 2px 4px rgba(0,0,0,0.3)"
                    }}
                >
                    Day 3: LinkedIn Profile Optimization
                </Typography>

                <Grid container spacing={4}>
                    {/* Video and Description Section */}
                    <Grid item xs={12}>
                        <Paper
                            elevation={10}
                            sx={{
                                borderRadius: 3,
                                overflow: 'hidden',
                                background: "rgba(255,255,255,0.9)"
                            }}
                        >
                            <Grid container>
                                {/* Video Column */}
                                <Grid
                                    item
                                    xs={12}
                                    md={7}
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <iframe
                                        title="LinkedIn Profile Optimization Video"
                                        src="https://www.youtube.com/embed/your-linkedin-video-id"
                                        style={{
                                            width: "100%",
                                            height: isMobile ? "300px" : "400px",
                                            borderRadius: "16px",
                                            boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
                                        }}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                </Grid>

                                {/* Description Column */}
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        p: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: 2,
                                            color: "primary.main"
                                        }}
                                    >
                                        Master Your LinkedIn Presence
                                    </Typography>
                                    <Typography variant="body1">
                                        Discover how to transform your LinkedIn profile into a powerful professional brand. Learn strategies to attract recruiters and network effectively.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Single Card Section */}
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                borderRadius: 3,
                                p: 3,
                                background: "rgba(255,255,255,0.95)",
                                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                transition: 'transform 0.3s ease-in-out',
                                   '&:hover': {
                                     transform: 'scale(1.05)'
                                   },
                                minHeight: '200px'
                            }}
                            elevation={6}
                        >
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: "bold",
                                        mb: 2,
                                        color: "primary.main"
                                    }}
                                >
                                    LinkedIn Profile Review Checklist
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 3 }}>
                                    Download our comprehensive checklist to optimize your LinkedIn profile and enhance your professional presence.
                                </Typography>
                                <Button
                                    sx={{backgroundColor: "#1cdb35"}}
                                    variant="contained"
                                    color="success"
                                    onClick={handleDownload}
                                >
                                    Download Checklist
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </Box>
    );
};

export default BootCampDay3;
