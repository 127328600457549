
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  OutlinedInput,
  CircularProgress,
  FormLabel
} from '@mui/material';
import Navbar from "../components/Navbar"
import Footer from '../components/Footer'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDropzone } from 'react-dropzone';


const CompleteProfile = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
   // email: '',
    phone: '',
    city: '',
    currentRole: '',
    organization: '',
    skills: [],
    bio: '',
    availability: []
  });
  const [customSkill, setCustomSkill] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();

  const commonSkills =  [ "Project Management",
    "Software Development",
    "UI/UX Design",
    "Quality Assurance",
    "Business Analysis",
    "Data Analysis",
    "Agile Methodologies",
    "Team Leadership",
    "Communication Skills",
    "Problem-Solving",
    "Stakeholder Engagement",
    "Strategic Planning" ];
    
  const availabilityOptions = ['Thursday 7:30 PM AEST', 'Thursday 8:00 PM AEST', 'Friday 12:00 PM AEST', 'Friday 12:30 PM AEST'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSkillChange = (event) => {
    const value = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setFormData({ ...formData, skills: value });
  };

  const handleAddCustomSkill = () => {
    if (customSkill.trim()) {
      setFormData((prevData) => ({ ...prevData, skills: [...prevData.skills, customSkill.trim()] }));
      setCustomSkill('');
    }
  };

  const handleAvailabilityChange = (event) => {
    const value = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setFormData({ ...formData, availability: value });
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };

  const handleImageDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFormData({ ...formData, profileImage: file });
    setImagePreview(URL.createObjectURL(file));
  };
  



  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setLoading(true);
  
    const bioWords = formData.bio.trim().split(/\s+/);
    if (bioWords.length < 10) {
      setError('Bio must contain at least 10 words. Please provide more details about yourself.');
      setLoading(false);
      return;
    }
  
    const token = localStorage.getItem('authToken');
    const formDataToSend = new FormData();
  
    // Append all form data
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'profileImage' && value) {
        formDataToSend.append(key, value); // Attach image file
      } else if (Array.isArray(value)) {
        value.forEach((item) => formDataToSend.append(key, item)); // Handle arrays
      } else {
        formDataToSend.append(key, value); // Handle other fields
      }
    });
  
    try {
      await axios.put(
        'https://d18ye91bhcj72x.cloudfront.net/api/profile/complete-profile',
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      setSuccessMessage('Profile completed successfully.');
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || 'An error occurred while completing the profile.');
    } finally {
      setLoading(false);
    }
  };
  

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleImageDrop,
    accept: 'image/*',
    multiple: false,
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", width:"100vw" }}>
    <Navbar />
    <div style={{ 
        flex: 1, 
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems:"center", 
        width: "100%" 
      }}
    >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#fff",
        width: { xs: "80%", sm: "60%", md: "50%" },
        padding: "30px",
        borderRadius: "8px",
        marginY: "30px",
        boxShadow: 3,
        gap: 3
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
        Start Your Journey Here
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', maxWidth: '80%' }}>
        Tell us about yourself so we can match you with others with similar skills and help build your network.
      </Typography>

      {successMessage && (
        <Alert severity="success" sx={{ marginBottom: "10px" }}>
          {successMessage}
        </Alert>
      )}

      {error && (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" sx={{ width: { xs: "100%", sm: "28rem" }, gap: 2 }}>
          <TextField label="First Name" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
          <TextField label="Last Name" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
          {/* <TextField label="Email" name="email" value={formData.email} onChange={handleInputChange} required /> */}

          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <FormLabel sx={{ marginBottom: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>Phone Number</FormLabel>
            <PhoneInput
              country={'au'}
              value={formData.phone}
              onChange={handlePhoneChange}
              inputStyle={{ width: '100%' }}
              containerStyle={{ width: '100%' }}
              placeholder="Enter phone number"
              required
            />
          </FormControl>

          <TextField label="City" name="city" value={formData.city} onChange={handleInputChange} required />

          <FormControl fullWidth>
            <InputLabel>Current Role</InputLabel>
            <Select name="currentRole" value={formData.currentRole} onChange={handleInputChange} input={<OutlinedInput label="Current Role" />} required>
              {['Project Manager','Test Lead', 'Developer', 'Designer','QA Tester', 'Business Analyst', 'IT Service Delivery Manager ', 'Market Research Analyst', 'Head of solutions architecture ', 'eGRC Solution Architect', 'Quality Analyst ( Software Testing ) ', 'Service Delivery Manager ', 'Test Specialist',  'Consultant', 'Software Project Manager', 'Project Engineer Civil',  'Sustainability Consultant', 'GRC Solution Architect, Director', 'Business Analyst', 'Senior Data Engineer', 'Currently looking for job', 'Customer Scientist', 'Student',  'Counsellor ', 'Scrum master', 'Senior IT Project Delivery Lead', 'Senior manager', 'NA', 'Data Analyst'].map(role => (
                <MenuItem key={role} value={role}>{role}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField label="Organization" name="organization" value={formData.organization} onChange={handleInputChange} required />

          <FormControl fullWidth>
            <InputLabel>Skills</InputLabel>
            <Select
              multiple
              value={formData.skills}
              onChange={handleSkillChange}
              input={<OutlinedInput label="Skills" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => <Chip key={value} label={value} />)}
                </Box>
              )}
            >
              {commonSkills.map((skill) => (
                <MenuItem key={skill} value={skill}>{skill}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Add Custom Skill"
            value={customSkill}
            onChange={(e) => setCustomSkill(e.target.value)}
            onBlur={handleAddCustomSkill}
            helperText="Press enter to add custom skill"
          />

          <TextField
            label="About Me (Bio)"
            name="bio"
            value={formData.bio}
            onChange={handleInputChange}
            multiline
            rows={4}
            inputProps={{ maxLength: 200 }}
            helperText={`${formData.bio.length}/200`}
            required
          />

          <FormControl fullWidth>
            <InputLabel>Availability *</InputLabel>
            <Select
              multiple
              value={formData.availability}
              required
              onChange={handleAvailabilityChange}
              input={<OutlinedInput label="Availability" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => <Chip key={value} label={value} />)}
                </Box>
              )}
            >
              {availabilityOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>

            <Box
              {...getRootProps()}
              sx={{
                border: '2px dashed lightgray',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '8px',
                marginY:"20px"
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography>Drop the image here...</Typography>
              ) : (
                <Typography>Drag & drop an image here, or click to select one</Typography>
              )}
              {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '100%', marginTop: '10px' }} />}
            </Box>
          </FormControl>

          <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Complete Profile'}
          </Button>
        </Box>
      </form>
    </Box>
    </div>
    <Footer />
    </div>
  );
};

export default CompleteProfile;
