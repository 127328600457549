import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Container,
  Grid,
  Paper,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const BootCampDay5 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      minHeight: "100vh",
      background: "#51ade5"
    }}>
      <Navbar />
      <Container maxWidth="lg" sx={{ flexGrow: 1, py: 4 }}>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{
            color: "white",
            fontWeight: "bold",
            mb: 4,
            textShadow: "2px 2px 4px rgba(0,0,0,0.3)"
          }}
        >
          Day 5: Attend Live QNA
        </Typography>

        <Grid container spacing={4}>
          {/* Video and Description Section */}
          <Grid item xs={12}>
            <Paper
              elevation={10}
              sx={{
                borderRadius: 3,
                overflow: 'hidden',
                background: "rgba(255,255,255,0.9)"
              }}
            >
              <Grid container>
                {/* Video Column */}
                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img
                    src="https://via.placeholder.com/600x400"
                    alt="Market Research"
                    style={{
                      width: "100%",
                      height: isMobile ? "300px" : "400px",
                      borderRadius: "16px",
                      boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
                    }}
                  />
                </Grid>

                {/* Description Column */}
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      color: "primary.main"
                    }}
                  >
                    Get Your Questions Answered
                  </Typography>
                  <Typography variant="body1">
                    Learn the strategies to succeed in technical and behavioral interviews. Prepare yourself with common questions and personalized answers.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Single Card Section */}
          <Grid item xs={12}>
            <Card
              sx={{
                borderRadius: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Center align horizontally
                textAlign: 'center',  // Center align text
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)'
                }
              }}
              elevation={6}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',  // Center align horizontally
                  justifyContent: 'center', // Center align vertically if needed
                  width: '100%', // Ensure full width alignment
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    color: "primary.main"
                  }}
                >
                  Your Q&A session Link
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Link for your session on Friday at 7 P.M AEST
                </Typography>
               {/* <Button
                  variant="contained"
                  color="success"
                  onClick={() => alert('Common Q&A PDF Download Coming Soon!')}
                >
                  Download Q&A
                </Button> */}
              </CardContent>
            </Card>
          </Grid>


        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default BootCampDay5;
