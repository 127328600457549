import { Box, Typography, Avatar } from "@mui/material";
import { Event, AccessTime } from "@mui/icons-material";

// Function to format the date (Day Abbreviation, Date)
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: "short", day: "numeric", month: "short" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

// Function to format the time
const formatTime = (dateString, timeSlot) => {
  if (!timeSlot || !dateString) {
    return "Invalid Time";
  }

  const [, time, period] = timeSlot.split(" "); 

  if (!time || !period) {
    return "Invalid Time";
  }

  const [hours, minutes] = time.split(":");

  if (isNaN(hours) || isNaN(minutes)) {
    return "Invalid Time";
  }

  const fullDateTime = new Date(dateString);
  const hour = period === "PM" ? (parseInt(hours) % 12) + 12 : parseInt(hours);
  fullDateTime.setHours(hour, parseInt(minutes), 0);

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(fullDateTime);
};

// Function to get the background and text color based on status
const getStatusStyles = (status) => {
  switch (status) {
    case "completed":
      return { backgroundColor: "#E8F5E9", color: "#2E7D32" }; // Green
    case "canceled":
      return { backgroundColor: "#FFEBEE", color: "#C62828" }; // Red
    case "scheduled":
      return { backgroundColor: "#FFF9C4", color: "#FBC02D" }; // Yellow
    case "confirmed":
      return { backgroundColor: "#E3F2FD", color: "#1E88E5" }; // Blue
    default:
      return { backgroundColor: "#E0E0E0", color: "#757575" }; // Default gray
  }
};


const AppointmentCard = ({ image, name, role, city, date, time, status, onClick }) => {
  const { backgroundColor, color } = getStatusStyles(status);

  return (
    <Box
     onClick={onClick}
      sx={{
        width: { xs: "80%", lg: "90%" },
        maxWidth: "1200px",
        bgcolor: "white",
        color: "black",
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        p: 3,
        mb: 3,
        mx: "auto",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 4 }}>
      <img
        src={image || "../images/Account_Dummy.png"}
        alt={name}
        style={{
          width: "80px",
          height: "80px",
          objectFit: "cover",
          borderRadius: "50%",
        }}
      />
        <Box>
          <Typography variant="h5" fontWeight="bold" sx={{ color: "#111111" }}>
            {name}
          </Typography>
          <Typography variant="body2" color="grey.600">
            {role}, {city}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          p: 2,
          mb: 4,
          borderRadius: "12px",
          bgcolor: "#E3F2FD",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: {xs: 1, lg:3}, mx: {xs:0, lg:2} }}>
          <Event fontSize="medium" sx={{ color: "#1E88E5" }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{
              color: "#1E88E5",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: { xs: "120px", sm: "none" },
            }}
          >
            {formatDate(date)}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mx: 2 }}>
          <AccessTime fontSize="medium" sx={{ color: "#1E88E5" }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{
              color: "#1E88E5",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: { xs: "120px", sm: "none" },
            }}
          >
            {formatTime(date, time)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: 1,
          borderRadius: "8px",
          textAlign: "center",
          fontWeight: "bold",
          bgcolor: backgroundColor,
          color: color,
        }}
      >
        <Typography variant="button">{status}</Typography>
      </Box>
    </Box>
  );
};

export default AppointmentCard;
